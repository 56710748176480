import React, { useState, useEffect } from 'react';
import Skills from '../components/portfolio/Skills';
import Examples from '../components/portfolio/Examples';
import Projects from '../components/portfolio/Projects';

interface PortfolioPageProps {
    activeSection: string;
    setActiveSection: (section: string) => void;
}

const PortfolioPage: React.FC<PortfolioPageProps> = ({ activeSection, setActiveSection }) => {

    useEffect(() => {
        setActiveSection('skills');
    }, [setActiveSection]);

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['skills', 'examples', 'projects'];
            for (let i = 0; i < sections.length; i++) {
                const sectionElement = document.getElementById(sections[i]);
                if (sectionElement) {
                    const rect = sectionElement.getBoundingClientRect();
                    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                    if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
                        setActiveSection(sections[i]);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setActiveSection]);

    return (
        <main>
            <section id="skills" style={{ paddingTop: '0' }}>
                <Skills activeSection={activeSection}/>
            </section>
            <section id="examples">
                <Examples activeSection={activeSection}/>
            </section>
            <section id="projects">
                <Projects activeSection={activeSection}/>
            </section>
        </main>
    );
};

export default PortfolioPage;