import React, {useEffect, useRef, useState} from 'react';
import section from "../../styles/section.module.css";
import {motion} from "framer-motion";
import arrow from "../../styles/scroll.module.css";
import fader from "../../styles/fader.module.css";

interface PresentProps {
    activeSection: string;
}

interface TimePeriod {
    Time: string;
    description: string;
}

const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3,
        },
    },
};

const contentVariants = {
    hidden: { opacity: 0, x: -400 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1.2,
            ease: "easeInOut",
        },
    },
};

const Present: React.FC<PresentProps> = ({ activeSection }) => {
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const myPresent = `Throughout my degree, I have had exposure to a vast range of languages, tools, frameworks, and concepts. With one trimester remaining, I have a perfect GPA of 7.0 and a WAM of 92.5. I am currently organising an honours research year (to be completed part-time over two years) in topics around security-first development, IoT networking, Edge Computing, adaptive learning systems, zero trust architecture and embedded AI (Deep Learning focused). My current focus is on obtaining the AWS Developer Associate certification.`;

    const aboutData: TimePeriod[] = [
        { Time: 'My Present', description: myPresent },
    ];

    const handleClick = () => {
        const aboutSection = document.getElementById("future");
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop + 180,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };

    useEffect(() => {
        if (activeSection === 'present') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);

    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id="present"
            className={`${fader.container} ${activeSection === 'present' ? fader.fadeIn : fader.fadeOut}`}
            ref={sectionRef}
        >
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate={isVisible ? "visible" : "hidden"}
                onAnimationComplete={() => {
                    if (isVisible && activeSection === 'about') {
                        setScrollIndicatorVisible(true);
                    }
                }}
            >
                {aboutData.map((time, index) => (
                    <motion.div
                        key={index}
                        variants={contentVariants}
                    >
                        <p><strong>{time.Time}</strong></p>
                        <p>{time.description}</p>
                    </motion.div>
                ))}
            </motion.div>

            {isScrollIndicatorVisible && (
                <motion.div
                    className={arrow.scrollIndicator}
                    variants={scrollIndicatorVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    whileInView="bounce"
                    onClick={handleClick}
                    style={{cursor: 'pointer'}}
                >
                    <span className={arrow.arrow}>↓</span>
                </motion.div>
            )}
        </section>
    );
};

export default Present;