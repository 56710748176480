import React, {useEffect, useState} from 'react';
import { motion } from 'framer-motion';
import contactStyles from '../../styles/contact.module.css';
import section from '../../styles/section.module.css';
import emailjs from '@emailjs/browser';
import contactImage from '../../assets/contact.png';
import arrow from '../../styles/scroll.module.css';

type FormState = {
    name: string;
    email: string;
    message: string;
};

interface ContactProps {
    activeSection: string;
}

const Contact: React.FC<ContactProps> = ({ activeSection }) => {
    const [formData, setFormData] = useState<FormState>({ name: '', email: '', message: '' });
    const [submitted, setSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false); // Track the sending status
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleClick = () => {
        const aboutSection = document.getElementById("welcome");
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsSending(true);

        try {
            const result = await emailjs.send('service_tpdlw6g', 'template_j080myq', formData, 'CfqDGVdgrNdzKcyTk');

            if (result.status === 200) {
                setSubmitted(true);
            } else {
                console.error('Error sending message:', result.text);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSending(false);
        }
    };

    useEffect(() => {
        if (activeSection === 'contact') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);


    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    return (
        <main id="contact" className={section.container}>
            <motion.div
                className={contactStyles.contactForm}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.6 }}
            >
                <img
                    src={contactImage}
                    alt="Contact Icon"
                    className={contactStyles.contactImage}
                />
                <h2 className={section.blackHeader}>Get in Touch</h2>
                {!submitted ? (
                    <form onSubmit={handleSubmit} className={contactStyles.form}>
                        <div className={contactStyles.formGroup}>
                            <label htmlFor="name" className={contactStyles.label}>Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                className={contactStyles.input}
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={contactStyles.formGroup}>
                            <label htmlFor="email" className={contactStyles.label}>Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className={contactStyles.input}
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                            />
                        </div>

                        <div className={contactStyles.formGroup}>
                            <label htmlFor="message" className={contactStyles.label}>Message</label>
                            <textarea
                                id="message"
                                name="message"
                                className={contactStyles.textarea}
                                value={formData.message}
                                onChange={handleInputChange}
                                rows={6}
                                required
                            />
                        </div>

                        <motion.button
                            type="submit"
                            className={contactStyles.submitButton}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            disabled={isSending}
                        >
                            {isSending ? 'Sending...' : 'Send'}
                        </motion.button>
                    </form>
                ) : (
                    <motion.p
                        className={contactStyles.successMessage}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        Thank you! Your message has been sent.
                    </motion.p>
                )}
            </motion.div>
        </main>
    );
}

export default Contact;