import React, { useEffect, useRef, useState } from 'react';
import educationStyle from '../../styles/education.module.css';
import fader from '../../styles/fader.module.css';
import section from '../../styles/section.module.css';
import eduImage from '../../assets/education.png';
import { motion } from 'framer-motion';
import arrow from '../../styles/scroll.module.css';

interface Education {
    school: string;
    degree: string;
    year: string;
    description: string;
    link?: string;
}

const containerVariants = {
    hidden: { opacity: 1 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.3,
        },
    },
};

const contentVariants = {
    hidden: { opacity: 0, x: -400 },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1.2,
            ease: "easeInOut",
        },
    },
};

interface EducationProps {
    activeSection: string;
}

const Education: React.FC<EducationProps> = ({ activeSection }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);

    const educationData: Education[] = [
        {
            school: 'University of New England',
            degree: 'Bachelor of Computer Science',
            year: '2022 - 2025',
            description: 'Majoring in Software Development (7.0 GPA / WAM 92.5).',
            link: 'https://handbook.une.edu.au/courses/2022/BCOMP?year=2022',
        },
        {
            school: 'New Zealand Institute of Sport',
            degree: 'Diploma in Exercise Prescription',
            year: '2009',
            description: 'Graduated with academic excellence and sportsman of the year.',
            link: 'https://www.nzis.co.nz/',
        },
        {
            school: 'New Zealand Institute of Sport',
            degree: 'Certificate in Sport Management',
            year: '2008',
            description: '',
            link: 'https://www.nzis.co.nz/',
        },
    ];

    const handleClick = () => {
        const aboutSection = document.getElementById("history");
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop + 180,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };

    useEffect(() => {
        if (activeSection === 'education') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);

    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <section
            id="education"
            className={`${fader.container} ${activeSection === 'education' ? fader.fadeIn : fader.fadeOut}`}
            style={{ paddingTop: '20px' }}
            ref={sectionRef}
        >
            <img
                src={eduImage}
                alt="Education Icon"
                className={educationStyle.educationImage}
            />
            <div className={educationStyle.educationSection}>
                <h2 className={section.blackHeader}>Education</h2>

                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={isVisible ? "visible" : "hidden"}
                >
                    {educationData.map((education, index) => (
                        <motion.div
                            key={index}
                            className={educationStyle.educationItem}
                            variants={contentVariants}
                        >
                            {education.link ? (
                                <a href={education.link} target="_blank" rel="noopener noreferrer">
                                    <p><strong>{education.degree}</strong></p>
                                    <p>{education.school}: {education.year}</p>
                                    <p>{education.description}</p>
                                </a>
                            ) : (
                                <>
                                    <p><strong>{education.degree}</strong></p>
                                    <p>{education.school}: {education.year}</p>
                                    <p>{education.description}</p>
                                </>
                            )}
                        </motion.div>
                    ))}
                </motion.div>
            </div>
            {isScrollIndicatorVisible && (
                <motion.div
                    className={arrow.scrollIndicator}
                    variants={scrollIndicatorVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    whileInView="bounce"
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                >
                    <span className={arrow.arrow}>↓</span>
                </motion.div>
            )}
        </section>
    );
};

export default Education;