import React, { useRef, useState, useEffect } from 'react';
import projectsStyle from '../../styles/projects.module.css';
import fader from '../../styles/fader.module.css';
import section from '../../styles/section.module.css';
import projectsImage from '../../assets/projects.png';
import { Tilt } from "react-tilt";
import { motion } from 'framer-motion';
import arrow from '../../styles/scroll.module.css';

const containerVariants = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.8,
            staggerChildren: 0.4
        }
    }
};

const itemVariants = {
    hidden: { y: 30, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1
    }
};

interface ProjectsProps {
    activeSection: string;
}

const Projects: React.FC<ProjectsProps> = ({ activeSection }) => {
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);

    const projects = [
        { title: "Terminal Messaging Program", description: "A terminal-based client-server messaging program with a focus on Authentication and Encryption using RSA and AES and salted passwords with SHA-256.", link: "https://github.com/AndyMac124/TerminalMessaging" },
        { title: "SpaceMaze Game", description: "A few of the Java classes I was the sole author of in our class-wide mini-game server project.", link: "https://github.com/AndyMac124/SpaceMazeClasses" },
        { title: "Reversi Game", description: "A game of Reversi using a little AI utility agent, I authored the Reversi.scala and ReversiSuite.scala.", link: "https://github.com/AndyMac124/Reversi/tree/main" },
        { title: "Character Counting", description: "A C program which takes in a directory with plain text files to then perform a frequency analysis on the characters.", link: "https://github.com/AndyMac124/characterCounting/tree/main" },
    ];

    const defaultOptions = {
        reverse: false,
        max: 45,
        perspective: 500,
        scale: 1.1,
        speed: 500,
        transition: true,
        axis: null,
        reset: true,
        easing: "cubic-bezier(.03,.98,.52,.99)",
    };

    useEffect(() => {
        if (activeSection === 'projects') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);

    return (
        <div className={section.container}>
            <section
                id="projects"
                className={`${fader.container} ${activeSection === 'projects' ? fader.fadeIn : fader.fadeOut}`}
                ref={sectionRef}
            >
                <div className={projectsStyle.projects}>


                <img
                    src={projectsImage}
                    alt="Projects Icon"
                    className={projectsStyle.projectImage}
                />
                <h2 className={section.whiteHeader}>Projects</h2>
                <p>The cards below are linked to the GitHub Repos for the given projects, feel free to click on some and
                    have a look.</p>
                <p>I have a lot more projects I can't make public right now, some notable ones include the following:</p>
                <ul>
                    <li>&#10148; A <b>C</b> program using <b>MPI</b> to perform a convolution filter on a matrix.</li>
                    <li>&#10148; A <b>CUDA C</b> program to generate a Julia Fractal bitmap image.</li>
                    <li>&#10148; A game of Saboteur in <b>Python</b> with <b>PyGame</b> and two types of AI agents competing.</li>
                    <li>&#10148; Implementation of <b>Monte Carlo Tree Search</b> in the game of Connect Four.</li>
                </ul>

                <motion.div
                    className={projectsStyle.cardsContainer}
                    variants={containerVariants}
                    initial="hidden"
                    animate={activeSection === 'projects' ? "visible" : "hidden"}
                >
                    {projects.map((project, index) => (
                        <Tilt key={index} options={defaultOptions}>
                            <motion.div
                                className={projectsStyle.card}
                                variants={itemVariants}
                            >
                                <a href={project.link} target="_blank" rel="noopener noreferrer">
                                    <p><strong>{project.title}</strong></p>
                                    <p>{project.description}</p>
                                </a>
                            </motion.div>
                        </Tilt>
                    ))}
                </motion.div>
                </div>
            </section>
        </div>
    );
};

export default Projects;