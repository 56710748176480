import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import ScrollToTop from "../components/ScrollToTop";
import Header from '../components/header/Header';
import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import GamesPage from '../pages/GamesPage';
import PortfolioPage from '../pages/PortfolioPage';
import ContactPage from '../pages/ContactPage';
import '../styles/App.css';
import {motion} from "framer-motion";
import arrow from "../styles/scroll.module.css";

const App: React.FC = () => {
    const [activeSection, setActiveSection] = useState('welcome');
    const [backgroundColor, setBackgroundColor] = useState('#fff');

    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    useEffect(() => {
        const backgroundMap: Record<string, string> = {
            welcome: '#dee8f6',

            about: '#ede0a9',

            education: '#c0efed',
            history: '#c0daef',
            present: '#c0c9ef',
            future: '#ccc0ef',

            skills: '#ddd9c1',
            examples: '#d9ddc1',
            projects: '#cfddc1',


            dadjokes: '#c3811c',
            trivia: '#b89df6',

            blogOverview: '#75c0df',
            blogs: '#ffffff',

            contact: '#00adb5',
        };
        setBackgroundColor(backgroundMap[activeSection] || '#ffffff');
    }, [activeSection]);

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <Router>
            <div style={{ backgroundColor: backgroundColor, transition: 'background-color 0.5s' }}>
                <Header />
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<HomePage activeSection={activeSection} setActiveSection={setActiveSection}  />} />
                    <Route path="/about" element={<AboutPage activeSection={activeSection} setActiveSection={setActiveSection} />} />
                    <Route path="/portfolio" element={<PortfolioPage activeSection={activeSection} setActiveSection={setActiveSection} />} />
                    <Route path="/games" element={<GamesPage activeSection={activeSection} setActiveSection={setActiveSection} />} />
                    <Route path="/contact" element={<ContactPage activeSection={activeSection} setActiveSection={setActiveSection} />} />
                </Routes>
                <footer>
                    {activeSection !== 'welcome' && activeSection !== 'contact' && (
                        <motion.div
                            className={arrow.baseIndicator}
                            variants={scrollIndicatorVariants}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            whileInView="bounce"
                            onClick={handleClick}
                            style={{ cursor: 'pointer' }}
                        >
                            <span className={arrow.baseArrow}>↑</span>
                        </motion.div>
                    )}
                    <Link to="/contact" className="footerLink">Contact Me</Link>
                    <p>© 2024 Andrew McKenzie</p>
                </footer>
            </div>
        </Router>
    );
};

export default App;