export const fetchDadJokes = async (limit: number = 1): Promise<any[]> => {
    const apiKey = process.env.REACT_APP_API_KEY;
    if (!apiKey) {
        throw new Error("API key is not defined");
    }

    const url: string = `https://api.api-ninjas.com/v1/dadjokes`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'X-Api-Key': apiKey,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const jokes = await response.json();
        return jokes;

    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};
