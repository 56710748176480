import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import welcomeStyles from '../styles/welcome.module.css';
import {Link} from "react-router-dom";

interface HomePageProps {
    activeSection: string;
    setActiveSection: (section: string) => void;
}

const Welcome: React.FC<HomePageProps> = ({ activeSection, setActiveSection }) => {
    const letters = "Hi, I'm Andrew!".split("");
    const letters2 = "I build software.";

    const letterVariants = {
        hidden: { y: -100, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 400,
                damping: 8,
            },
        },
    };

    const containerVariants = {
        visible: {
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const letters2Variant = {
        hidden: { opacity: 0, y: -50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: letters.length * 0.14,
                type: 'spring',
                stiffness: 400,
                damping: 15,
            },
        },
    };

    const letters3Variant = {
        hidden: { opacity: 0, y: -50 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                delay: letters.length * 0.14 + 0.5,
                type: 'spring',
                stiffness: 400,
                damping: 15,
            },
        },
    };

    useEffect(() => {
        setActiveSection('welcome');
    }, [setActiveSection]);

    return (
        <motion.div
            className={welcomeStyles.welcomeContainer}
            variants={containerVariants}
            initial="hidden"
            animate={activeSection === 'welcome' ? 'visible' : 'hidden'}
        >
            <div className={welcomeStyles.firstLine}>
                {letters.map((letter, index) => (
                    <motion.span key={index} variants={letterVariants} className={welcomeStyles.letter}>
                        {letter === ' ' ? '\u00A0' : letter}
                    </motion.span>
                ))}
            </div>

            <motion.div className={welcomeStyles.letters2Container} variants={letters2Variant}>
                {letters2}
            </motion.div>

            <motion.div className={welcomeStyles.letters3Container} variants={letters3Variant}>
                <nav className={welcomeStyles.nav}>
                    <Link to="/about" className={welcomeStyles.navButton}>About</Link>
                    <Link to="/portfolio" className={welcomeStyles.navButton}>Portfolio</Link>
                    <Link to="/games" className={welcomeStyles.navButton}>Games</Link>
                </nav>
            </motion.div>

        </motion.div>
    );
};

export default Welcome;