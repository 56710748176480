import React, { useEffect, useRef, useState } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import exampleStyle from '../../styles/examples.module.css';
import fader from '../../styles/fader.module.css';
import section from '../../styles/section.module.css';
import projectsImage from '../../assets/projects.png';
import DashboardDark from '../../assets/Dashboard_dark.jpg';
import DashboardLight from '../../assets/Dashboard_light.jpg';
import JuliaFractal from '../../assets/my_julia_fractal.jpg';
import Saboteurs from '../../assets/Saboteurs.jpg';
import SpacemazeGame from '../../assets/Spacemaze_game.jpg';
import SpacemazeGuide from '../../assets/Spacemaze_guide.jpg';
import TerminalMessaging from '../../assets/TerminalMessage.jpg';
import arrow from '../../styles/scroll.module.css';
import { motion } from 'framer-motion';


interface ExamplesProps {
    activeSection: string;
}

const Examples: React.FC<ExamplesProps> = ({ activeSection }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [expandedItems, setExpandedItems] = useState<number[]>([]);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);

    const handleClick = () => {
        const aboutSection = document.getElementById("projects");
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop + 180,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };

    const items = [
        {
            id: 1,
            title: 'Dashboard Dark Theme',
            description: 'Admin Dashboard (Dark theme) for a Blog Website',
            image: DashboardDark,
            content:
                'An admin dashboard for managing a blog website, built in Laravel using a MVC architecture with MySQL database and RESTful APIs for the frontend (built in React with Typescript).',
        },
        {
            id: 2,
            title: 'Dashboard Light Theme',
            description: 'Admin Dashboard (Light theme) for a Blog Website',
            image: DashboardLight,
            content:
                'An admin dashboard for managing a blog website, built in Laravel using a MVC architecture with MySQL database and RESTful APIs for the frontend (built in React with Typescript).',
        },
        {
            id: 3,
            title: 'Julia Fractal Visualization',
            description: 'Julia fractal visualization using CUDA C',
            image: JuliaFractal,
            content:
                'A visual representation of Julia fractals generated using GPU programming with CUDA C',
        },
        {
            id: 4,
            title: 'Saboteurs Game Interface',
            description: 'Saboteurs game UI design',
            image: Saboteurs,
            content:
                'The UI for a game of Saboteurs, this game was automated using two types of AI agents playing each other.',
        },
        {
            id: 5,
            title: 'Space Maze Game Screenshot',
            description: 'Space Maze game in action',
            image: SpacemazeGame,
            content:
                'A snapshot of the Space Maze game. This was built in Java using OOP within a team of four, managed in Gitlab and the class project was monitored and deployed with Jenkins.',
        },
        {
            id: 6,
            title: 'Space Maze Guide',
            description: 'Guide to playing Space Maze',
            image: SpacemazeGuide,
            content:
                'An in-depth guide for players on how to navigate the Space Maze game.',
        },
        {
            id: 7,
            title: 'Terminal Messaging Interface',
            description: 'Terminal messaging system',
            image: TerminalMessaging,
            content:
                'An interface for the terminal-based messaging system, highlighting command-line interactions and encryption of messages.',
        },
    ];

    const handleItemClick = (id: number) => {
        if (expandedItems.includes(id)) {
            setExpandedItems(expandedItems.filter(itemId => itemId !== id));
        } else {
            setExpandedItems([...expandedItems, id]);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                setIsVisible(entry.isIntersecting);
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (activeSection === 'examples') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);


    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    return (
        <div className={section.container}>
            <div className={exampleStyle.examples} >
            <section
                id="examples"
                className={`${fader.container} ${
                    isVisible ? fader.fadeIn : fader.fadeOut
                }`}
                ref={sectionRef}
            >
                <img
                    src={projectsImage}
                    alt="Projects Icon"
                    className={exampleStyle.exampleImage}
                />
                <h2>Examples</h2>
                <p>Expand the descriptions below to see images of the projects.</p>
                <div className={exampleStyle.itemsContainer}>
                    {items.map(item => (
                        <div key={item.id} className={exampleStyle.item}>
                            <div
                                className={exampleStyle.itemHeader}
                                onClick={() => handleItemClick(item.id)}
                                role="button"
                                tabIndex={0}
                                onKeyPress={() => handleItemClick(item.id)}
                            >
                                <span
                                    className={`${exampleStyle.arrow} ${
                                        expandedItems.includes(item.id)
                                            ? exampleStyle.arrowExpanded
                                            : ''
                                    }`}
                                ></span>
                                {item.description}
                            </div>
                            {expandedItems.includes(item.id) && (
                                <div className={exampleStyle.itemContent}>
                                    <Zoom>
                                        <img
                                            src={item.image}
                                            alt={`Screenshot of ${item.title}`}
                                            className={exampleStyle.clickableImage}
                                            onError={e => {
                                                e.currentTarget.src =
                                                    'path/to/placeholder/image.png';
                                            }}
                                        />
                                    </Zoom>
                                    <p>{item.content}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </section>
            </div>
            {isScrollIndicatorVisible && (
                <motion.div
                    className={arrow.scrollIndicator}
                    variants={scrollIndicatorVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    whileInView="bounce"
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                >
                    <span className={arrow.arrow}>↓</span>
                </motion.div>
            )}
        </div>
    );
};

export default Examples;