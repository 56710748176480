import React, { useEffect, useRef, useState } from 'react';
import skillsStyle from '../../styles/skills.module.css';
import fader from '../../styles/fader.module.css';
import SkillImages from './SkillsImages';
import skillImage from '../../assets/skills.png';
import section from '../../styles/section.module.css';
import arrow from '../../styles/scroll.module.css';
import { motion } from 'framer-motion';


interface LanguageData {
    language: string;
    bytes: number;
}

interface SkillsProps {
    activeSection: string;
}

const Skills: React.FC<SkillsProps> = ({ activeSection }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [expandedItems, setExpandedItems] = useState<number[]>([]);
    const [languageData, setLanguageData] = useState<LanguageData[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);

    const items = [
        {
            id: 1,
            title: 'Java',
            description: 'Three years experience coding in Java (object-oriented programming)',
            content:
                'Three years of programming experience in Java (mostly Java 17) with an object-oriented design focus. I have\n' +
                'written programs in Java utilising security measures such as RSA, AES, and SHA-256 for salted passwords, Client-\n' +
                'Server architecture, Unit testing with JUnit, a space-themed maze game, a Missile Command replica game, and\n' +
                'many smaller programs.',
        },
        {
            id: 2,
            title: 'React w/ Typescript',
            description: 'One year of experience in React with Typescript',
            content:
                'For just over 12 months now, I have been gradually expanding my understanding of and proficiency with React. In an Advanced Web unit at university last trimester, I tied this practice together with theory and a front-end blog site in React communicating via RESTful APIs with a backend admin site developed in Laravel (PHP framework) and a database in MySQL, which I temporarily converted to MongoDB for learning purposes.',
        },
        {
            id: 3,
            title: 'Management Information Systems',
            description: 'Thorough understanding of Management Information Systems and their relation to business processes',
            content:
                'Management information systems was one of my most dominant units. I excelled at tasks such as critically analysing cloud\n' +
                'solutions for digital banking and completing the unit with 98/100 and 100% on the final proctored exam.',
        },
        {
            id: 4,
            title: 'C Programming',
            description: 'Low-level C programming, including MPI, CUDA C, and POSIX programming',
            content:
                'I am a big fan of C when speed and reliability are critical. I have written various programs in C\n' +
                'handling memory management and providing concurrency through Multi-threaded and Multi-process\n' +
                'programming with POSIX library functions and Open MPI for distributed computing, plus some CUDA C GPU\n' +
                'programming.',
        },
        {
            id: 5,
            title: 'Git version control',
            description: 'Git, Github, and Gitlab',
            content:
                'I have been using Git consistently for around six months now. I have completed a short course with ‘Code\n' +
                'With Mosh’ plus a university course with assignments focused on Git, including merging, rebasing, etc. Although I\n' +
                'can’t claim to be an expert given the complexity of Git, I feel very comfortable with daily use.',
        },
        {
            id: 6,
            title: 'API Development',
            description: 'RESTful API Development and public API integration',
            content:
                'I have experience developing RESTful APIs and testing through POSTMAN. I have also integrated public APIs into this website below.'
        },
        {
            id: 7,
            title: 'Laravel/MVC Architecture',
            description: 'Working with Laravel to build a blog site which uses an MVC architecture',
            content:
                'As mentioned above, I have experience building a blog site with Laravel and Laravel\n' +
                'was the focus of the Advanced Web Programming unit. Hence, I feel very competent and efficient with the Laravel\n' +
                'framework. This framework is also built around an MVC architecture, with which I now have plenty of exposure.',
        },
    ];

    const handleClick = () => {
        const projects = document.getElementById("examples");
        if (projects) {
            window.scrollTo({
                top: projects.offsetTop + 180,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };

    const handleItemClick = (id: number) => {
        if (expandedItems.includes(id)) {
            setExpandedItems(expandedItems.filter(itemId => itemId !== id));
        } else {
            setExpandedItems([...expandedItems, id]);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true); // Fade in
                } else {
                    setIsVisible(false); // Fade out
                }
            });
        }, {
            threshold: [0, 0.25, 0.5, 0.75, 1],
            rootMargin: '0px 0px -20% 0px',
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);



    useEffect(() => {
        const fetchLanguageData = async () => {
            try {
                setLoading(true);
                const response = await fetch(
                    'https://github-readme-stats-l279.vercel.app/api/top-langs/?username=AndyMac124&layout=compact&langs_count=12&size_weight=0.5&count_weight=0.5'
                );
                if (!response.ok) {
                    throw new Error('Failed to fetch language data');
                }
                const data = await response.json();
                setLanguageData(data);
            } catch (error: any) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLanguageData();
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://badgr.com/assets/widgets.bundle.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (activeSection === 'skills') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);

    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    return (
        <section
            id="skills"
            className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut} ${section.container}`}
            style={{ paddingTop: '20px' }}
            ref={sectionRef}
        >
            <img
                src={skillImage}
                alt="Skills Icon"
                className={skillsStyle.skillImage}
            />
            <h2 className={section.header}>Skills</h2>
            <p>Throughout my degree, I have been exposed to a wide range of languages, tools, and frameworks. The
                languages I am most familiar with are <b>Java</b> and <b>C</b>. A stand-out Java project was during a
                class-wide
                mini-game server project where I led a team of 4 within the class of 40 to work together on a monorepo
                with Java and Scala. In this, we monitored the project through <b>Jenkins</b> and used <b>Gitlab</b> for
                version
                control, issues etc.
            </p>

            <p>Web Development involved building a static page with animations using <b>HTML5</b>, <b>CSS</b> and <b>vanilla
                JS</b> (before
                switching to <b>jQuery</b>), then Advanced web involved building and deploying a frontend blog
                using <b>React</b> and <b>Typescript</b>, as well as creating the API, database, and backend admin site
                with authentication
                using <b>Laravel</b>, <b>MySQL</b> and temporarily converting it to <b>MongoDB</b>.
            </p>

            <p>I've also covered a range of soft skills and business-specific skills such as building a Software
                development plan with a <b>Gantt Chart</b>,<b> dependency relationships</b>, <b>work breakdown
                    structure</b> etc. Plus essays on the use of cloud computing in business, data management in social
                business, and case studies on failed software projects to name a few.</p>

            {loading && <p>Loading language stats...</p>}
            {languageData && (
                <ul>
                    {languageData.map((lang, index) => (
                        <li key={index}>
                            {lang.language}: {lang.bytes} bytes
                        </li>
                    ))}
                </ul>
            )}
            <br></br>
            <a href="https://github.com/AndyMac124" target="_blank" rel="noopener noreferrer">
                <picture>
                    <source
                        srcSet="https://github-readme-stats-l279.vercel.app/api/top-langs/?username=AndyMac124&layout=compact&langs_count=12&size_weight=0.5&count_weight=0.5&hide_border=true&bg_color=00000000&title_color=333&custom_title=GitHub%20-%20Most%20Used%20Languages"
                        media="(prefers-color-scheme: dark)"
                    />
                    <source
                        srcSet="https://github-readme-stats-l279.vercel.app/api/top-langs/?username=AndyMac124&layout=compact&langs_count=12&size_weight=0.5&count_weight=0.5&hide_border=true&bg_color=00000000"
                        media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)"
                    />
                    <img src="https://github-readme-stats-l279.vercel.app/api?username=AndyMac124&show_icons=true"/>
                </picture>
            </a>
            <br></br>
            <hr></hr>
            <br></br>
            <h2><strong>Strongest Technical Skills</strong></h2>
            <p><i>Expand the descriptions below for further details.</i></p>
            <div className={skillsStyle.itemsContainer}>
                {items.map(item => (
                    <div key={item.id} className={skillsStyle.item}>
                        <div
                            className={skillsStyle.itemHeader}
                            onClick={() => handleItemClick(item.id)}
                            role="button"
                            tabIndex={0}
                            onKeyPress={() => handleItemClick(item.id)}
                        >
                            <span><strong>{item.title}{': '}</strong>{item.description}</span>
                            <span
                                className={`${skillsStyle.arrow} ${
                                    expandedItems.includes(item.id)
                                        ? skillsStyle.arrowExpanded
                                        : ''
                                }`}
                            ></span>
                        </div>
                        {expandedItems.includes(item.id) && (
                            <div className={skillsStyle.itemContent}>
                                <p>{item.content}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <blockquote
                className="badgr-badge"
                style={{
                    fontFamily: 'Helvetica, Roboto, "Segoe UI", Calibri, sans-serif',
                }}
            >
                <a href="https://api.badgr.io/public/assertions/ZfmvX-lSQkiipfs05-lFjA?identity__email=amcken33%40myune.edu.au">
                    <img
                        width="120px"
                        height="120px"
                        src="https://api.badgr.io/public/assertions/ZfmvX-lSQkiipfs05-lFjA/image"
                        alt="Postman API Fundamentals Student Expert Badge"
                    />
                </a>
                <p
                    className="badgr-badge-name"
                    style={{
                        hyphens: "auto",
                        overflowWrap: "break-word",
                        wordWrap: "break-word",
                        margin: 0,
                        fontSize: "16px",
                        fontWeight: 600,
                        lineHeight: 1.25,
                        textAlign: "center",
                        color: "#05012c",
                    }}
                >
                    Postman API Fundamentals Student Expert
                </p>
                <p className={skillsStyle.itemsContainer}>
                    <a
                        className="badgr-badge-verify"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://badgecheck.io?url=https%3A%2F%2Fapi.badgr.io%2Fpublic%2Fassertions%2FZfmvX-lSQkiipfs05-lFjA%3Fidentity__email%3Damcken33%2540myune.edu.au&amp;identity__email=amcken33%40myune.edu.au"
                        style={{
                            boxSizing: "content-box",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: 0,
                            fontSize: "12px",
                            fontWeight: "bold",
                            width: "48px",
                            height: "14px",
                            borderRadius: "4px",
                            border: "solid 1px black",
                            textDecoration: "none",
                            padding: "6px 16px",
                            color: "black",
                        }}
                    >
                        VERIFY
                    </a>
                </p>
            </blockquote>
            <br></br>
            <hr></hr>
            <br></br>
            <h2>Other Technical Skills Practiced</h2>
            <ul>
                <li>&#10004; <b>MongoDB</b> - Converted an SQL project to MongoDB.</li>
                <li>&#10004; <b>Bash Scripting</b> - Basic scripting and regular use of the CLI in Fedora and iOS.</li>
                <li>&#10004; <b>Python3 and PyGame</b> - Multiple units using Python, including AI programming.</li>
                <li>&#10004; <b>PHP</b> - Some raw backend development with PHP and much more with Laravel.</li>
                <li>&#10004; <b>SQL</b> - I have CLI experience with MySQL and desktop experience using MySQLWorkbench.</li>
                <li>&#10004; <b>Javascript</b> - Vanilla JS web development, jQuery, and more.</li>
                <li>&#10004; <b>Scala</b> - Functional programming and Munit for test-driven development.</li>
                <li>&#10004; <b>Docker</b> - Through University and a short course with ‘Code with Mosh’.</li>
                <li>&#10004; <b>Microservice Architecture</b> - I studied it at university, and it ties in nicely with my OOP
                    strength.
                </li>
                <li>&#10004; <b>Diagramming</b> - UML, Sequence, Gantt, Readme, Wikis.</li>
                <li>&#10004; <b>Software Development Lifecycle</b> - High distinction in Software Project Management plus group
                    projects using Kanban and Scrum.
                </li>
                <li>&#10004; <b>Jenkins</b> - Exposure through a class-wide monorepo project.</li>
            </ul>

            <SkillImages/>
            {isScrollIndicatorVisible && (
                <motion.div
                    className={arrow.scrollIndicator}
                    variants={scrollIndicatorVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    whileInView="bounce"
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                >
                    <span className={arrow.arrow}>↓</span>
                </motion.div>
            )}
        </section>
    );
};

export default Skills;