import React, { useEffect, useRef, useState } from 'react';
import JokesStyle from '../../styles/dadjokes.module.css';
import fader from '../../styles/fader.module.css';
import section from "../../styles/section.module.css";
import { fetchDadJokes } from '../../services/api';
import arrow from '../../styles/scroll.module.css';
import { motion } from 'framer-motion';

interface DadjokesProps {
    activeSection: string;
}

const DadJokes: React.FC<DadjokesProps> = ({ activeSection }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [jokes, setJokes] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);

    const handleClick = () => {
        const aboutSection = document.getElementById("trivia");
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop - 0,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setIsVisible(entry.isIntersecting);
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    const loadJokes = async () => {
        try {
            setLoading(true);
            setError(null);
            const jokesData = await fetchDadJokes(3);
            console.log(jokesData);
            if (Array.isArray(jokesData)) {
                setJokes(jokesData.map((jokeItem: any) => jokeItem.joke));
            } else {
                throw new Error('Unexpected response format');
            }
            setLoading(false);
        } catch (err: any) {
            setError('Failed to fetch jokes. Please try again later.');
            console.error(err);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadJokes();
    }, []);

    useEffect(() => {
        if (activeSection === 'dadjokes') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);


    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    return (
        <div className={section.container}>
            <section
                id="dadjokes"
                className={`${fader.container} ${isVisible ? fader.fadeIn : fader.fadeOut}`}
                style={{ paddingTop: '20px' }}
                ref={sectionRef}
            >
                <div>
                    <h2 className={section.whiteHeader}>A little API fun with Dad Jokes</h2>
                    {loading ? (
                        <p className={JokesStyle.white}>Loading...</p>
                    ) : error ? (
                        <p className={JokesStyle.white}>{error}</p>
                    ) : (
                        <ul>
                            {jokes.length > 0 ? (
                                jokes.map((joke, index) => (
                                    <p key={index} className={JokesStyle.white}>{joke}</p>
                                ))
                            ) : (
                                <li className={JokesStyle.white}>No jokes available</li>
                            )}
                        </ul>
                    )}
                    <button onClick={loadJokes} className={JokesStyle.button}>Refresh</button>
                </div>
            </section>
            {isScrollIndicatorVisible && (
                <motion.div
                    className={arrow.scrollIndicator}
                    variants={scrollIndicatorVariants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    whileInView="bounce"
                    onClick={handleClick}
                    style={{ cursor: 'pointer' }}
                >
                    <span className={arrow.whiteArrow}>↓</span>
                </motion.div>
            )}
        </div>
    );
};

export default DadJokes;