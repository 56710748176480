import React, { useEffect } from 'react';
import Contact from "../components/contact/Contact";

interface AboutPageProps {
    activeSection: string;
    setActiveSection: (section: string) => void;
}

const ContactPage: React.FC<AboutPageProps> = ({ activeSection, setActiveSection }) => {

    useEffect(() => {
        setActiveSection('contact');
    }, [setActiveSection]);

    return (
        <main>
            <section id="contact" style={{ paddingTop: '0' }}>
                <Contact activeSection={activeSection} />
            </section>
        </main>
    );
};

export default ContactPage;