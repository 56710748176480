import React, { useEffect, useRef, useState } from 'react';
import triviaStyle from '../../styles/trivia.module.css';
import section from "../../styles/section.module.css";
import arrow from '../../styles/scroll.module.css';
import { motion } from 'framer-motion';

interface TriviaProps {
    activeSection: string;
}

const Trivia: React.FC<TriviaProps> = ({ activeSection }) => {
    const [quizStarted, setQuizStarted] = useState<boolean>(false);
    const [topic, setTopic] = useState<string | null>(null);
    const [difficulty, setDifficulty] = useState<string | null>(null);
    const [questions, setQuestions] = useState<any[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [quizCompleted, setQuizCompleted] = useState<boolean>(false);
    const [chosenTopic, setChosenTopic] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [hasAnswered, setHasAnswered] = useState<boolean>(false);
    const [userAnswer, setUserAnswer] = useState<string | null>(null);
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isScrollIndicatorVisible, setScrollIndicatorVisible] = useState(true);

    const handleClick = () => {
        const aboutSection = document.getElementById("dadjokes");
        if (aboutSection) {
            window.scrollTo({
                top: aboutSection.offsetTop + 80,
                behavior: "smooth",
            });
        }
        setScrollIndicatorVisible(false);
    };


    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setIsVisible(entry.isIntersecting);
            });
        });

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    const loadQuestions = async (selectedTopic: string, selectedDifficulty: string) => {
        try {
            setLoading(true);
            setError(null);
            console.log(selectedTopic);
            const apiUrl = `https://opentdb.com/api.php?amount=10&category=${selectedTopic}&difficulty=${selectedDifficulty}&type=multiple`;
            const response = await fetch(apiUrl);
            const data = await response.json();

            if (data.results) {
                setQuestions(data.results);
                console.log("Questions fetched:", data.results);
            } else {
                throw new Error('Unexpected response format');
            }
        } catch (err: any) {
            setError('Failed to fetch questions. Please try again later.');
            console.error("Error fetching questions:", err);
        } finally {
            setLoading(false);
        }
    };

    const decodeHTML = (html: string) => {
        const textArea = document.createElement("textarea");
        textArea.innerHTML = html;
        return textArea.value;
    };

    const startQuiz = (selectedTopic: string, selectedDifficulty: string) => {
        setTopic(selectedTopic);
        setDifficulty(selectedDifficulty);
        setQuizStarted(true);
        setChosenTopic(true);
        setQuizCompleted(false);
        setScore(0);
        setCurrentQuestionIndex(0);
        loadQuestions(selectedTopic, selectedDifficulty);
    };

    const handleAnswer = (answer: string) => {
        const currentQuestion = questions[currentQuestionIndex];
        setUserAnswer(answer);
        setHasAnswered(true);
        if (answer === currentQuestion.correct_answer) {
            setScore(score + 1);
        }
    };

    const nextQuestion = () => {
        if (currentQuestionIndex === questions.length - 1) {
            setQuizCompleted(true);
        } else {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
        setHasAnswered(false);
        setUserAnswer(null);
    };

    const restartQuiz = () => {
        setQuizStarted(false);
        setTopic(null);
        setChosenTopic(false);
        setDifficulty(null);
        setQuestions([]);
        setQuizCompleted(false);
        setLoading(false);
    };

    useEffect(() => {
        if (activeSection === 'trivia') {
            setScrollIndicatorVisible(true);
        } else {
            setScrollIndicatorVisible(false);
        }
    }, [activeSection]);

    const scrollIndicatorVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { delay: 0.1 },
        },
    };

    if (!quizStarted) {
        return (
            <div className={section.container}>
                <h2 className={triviaStyle.header}>Trivia Game</h2>
                <button onClick={() => setQuizStarted(true)} className={triviaStyle.button}>Play</button>

            </div>
        );
    }

    if ((!topic || !difficulty) && !chosenTopic) {
        let selectedTopic = "9";
        let selectedDifficulty = "easy";
        return (
            <div className={section.container}>
                <h2 className={triviaStyle.header}>Select Topic and Difficulty</h2>
                <div className={triviaStyle.selectors}>
                    <label>Choose Topic:</label>
                    <select onChange={(e) => selectedTopic = e.target.value}>
                        <option value="9">General Knowledge</option>
                        <option value="25">Art</option>
                        <option value="10">Books</option>
                        <option value="11">Films</option>
                        <option value="27">Animals</option>
                        <option value="22">Geography</option>
                        <option value="23">History</option>
                        <option value="12">Music</option>
                        <option value="18">Science: Computers</option>
                        <option value="30">Science: Gadgets</option>
                        <option value="21">Sports</option>
                        <option value="14">TV</option>
                        <option value="28">Vehicles</option>
                        <option value="15">Video Games</option>
                    </select>
                </div>
                <div className={triviaStyle.selectors}>
                    <label>Choose Difficulty:</label>
                    <select onChange={(e) => selectedDifficulty = e.target.value}>
                        <option value="easy">Easy</option>
                        <option value="medium">Medium</option>
                        <option value="hard">Hard</option>
                    </select>
                </div>
                <button onClick={() => startQuiz(selectedTopic, selectedDifficulty)} className={triviaStyle.button}>Start Quiz</button>
            </div>
        );
    }

    if (loading && chosenTopic) {
        return (
            <div className={section.container}>
                <p>Loading questions...</p>
            </div>
        );
    }

    if (!quizCompleted && questions.length > 0) {
        const currentQuestion = questions[currentQuestionIndex];
        const answers = [...currentQuestion.incorrect_answers, currentQuestion.correct_answer].sort();

        return (
            <div className={section.container}>
                <h2 className={triviaStyle.header}>Question {currentQuestionIndex + 1}/10</h2>
                <p>{decodeHTML(currentQuestion.question)}</p>

                {hasAnswered ? (
                    <div>
                        <p>
                            {userAnswer === currentQuestion.correct_answer
                                ? "Well done, that's correct!"
                                : `Oops, not quite. The correct answer was: ${decodeHTML(currentQuestion.correct_answer)}`}
                        </p>
                        <button onClick={nextQuestion} className={triviaStyle.button}>Next Question</button>
                    </div>
                ) : (
                    <div>
                        {answers.map((answer, index) => (
                            <button key={index} onClick={() => handleAnswer(answer)} className={triviaStyle.button}>
                                {decodeHTML(answer)}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    }

    if (quizCompleted) {
        return (
            <div className={section.container}>
                <h2 className={triviaStyle.header}>Quiz Completed</h2>
                <p>Your Score: {score}/10</p>
                <button onClick={restartQuiz} className={triviaStyle.button}>Restart Quiz</button>
            </div>
        );
    }
    return null;
};

export default Trivia;