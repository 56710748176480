import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import headerStyles from '../../styles/header.module.css';
import logo from '../../assets/logo.png';

const navLinks = [
    { id: 'welcome', title: 'Home', path: '/' },
    { id: 'about', title: 'About', path: '/about' },
    { id: 'portfolio', title: 'Portfolio', path: '/portfolio' },
    { id: 'games', title: 'Games', path: '/games' },
    { id: 'contact', title: 'Contact', path: '/contact' },
];

const isMobileOrTablet = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return true;
    }

    if (/android/i.test(userAgent)) {
        return true;
    }

    return false;
};

const Header: React.FC = () => {
    const [activeSection, setActiveSection] = useState('welcome');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isMobileOrTabletDevice, setIsMobileOrTabletDevice] = useState<boolean>(isMobileOrTablet());

    useEffect(() => {
        const currentSection = navLinks.find(link => link.path === location.pathname)?.id || 'welcome';
        setActiveSection(currentSection);
    }, [location]);

    const toggleMenu = () => {
        if (isMobileOrTabletDevice) {
            setIsMenuOpen(!isMenuOpen);
        }
    };



    const handleNavigation = (sectionId: string, path: string) => {
        setActiveSection(sectionId);
        navigate(path);
        setIsMenuOpen(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleMouseLeave = () => {
        if (!isMobileOrTabletDevice) {
            setIsMenuOpen(false);
        }
    };

    return (
        <nav
            className={`${headerStyles.navbar} ${isMenuOpen ? headerStyles.expanded : headerStyles.collapsed}`}
            onMouseLeave={handleMouseLeave}
        >
            <div className={headerStyles.navbarContainer}>
                <div className={headerStyles.logo}>
                    <img src={logo} alt="Logo" />
                </div>

                <div className={headerStyles.menuHint} onClick={toggleMenu} onMouseEnter={() => setIsMenuOpen(true)}>
                    {isMenuOpen ? '✖' : '☰'}
                </div>

                <ul className={`${headerStyles.navLinks} ${isMenuOpen ? headerStyles.active : ''}`}>
                    {navLinks.map((link) => (
                        <li key={link.id} onClick={() => handleNavigation(link.id, link.path)}>
                            <Link
                                to={link.path}
                                className={activeSection === link.id ? headerStyles.activeLink : ''}
                            >
                                {link.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
    );
};

export default Header;
