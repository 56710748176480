import React, { useState, useEffect } from 'react';
import Trivia from '../components/games/Trivia';
import DadJokes from "../components/games/DadJokes";

interface GamesPageProps {
    activeSection: string;
    setActiveSection: (section: string) => void;
}

const GamesPage: React.FC<GamesPageProps> = ({ activeSection, setActiveSection }) => {

    useEffect(() => {
        setActiveSection('dadjokes');
    }, [setActiveSection]);

    useEffect(() => {
        const handleScroll = () => {
            const sections = ['dadjokes', 'trivia'];
            for (let i = 0; i < sections.length; i++) {
                const sectionElement = document.getElementById(sections[i]);
                if (sectionElement) {
                    const rect = sectionElement.getBoundingClientRect();
                    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                    if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
                        setActiveSection(sections[i]);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [setActiveSection]);

    return (
        <main>
            <section id="dadjokes" style={{ paddingTop: '0' }}>
                <DadJokes activeSection={activeSection}/>
            </section>
            <section id="trivia">
                <Trivia activeSection={activeSection}/>
            </section>
        </main>
    );
};

export default GamesPage;